import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../components/LandingPage.vue';
import SignUpForm from '../components/SignUpForm.vue';
import LoginForm from '../components/LoginForm.vue';
import MainPage from '../components/MainPage.vue';
import ExerciseForm from '../components/ExerciseForm.vue';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: LandingPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginForm,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpForm,
  },
  {
    path: '/main',
    name: 'Main',
    component: MainPage,
  },
  {
    path: '/exerciseform',
    name: 'ExerciseForm',
    component: ExerciseForm,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
