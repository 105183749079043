<template>
  <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
    <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
      <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-visible">
        <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div class="w-full md:w-1/2">
            <form class="flex items-center">
              <label for="simple-search" class="sr-only">Search</label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <input v-model="searchQuery" type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required>
              </div>
            </form>
          </div>
          <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div class="w-52">
              <select v-model="sortOrder" class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
            <fwb-button color="default" @click="showAddModal">
              Add PR
            </fwb-button>
          </div>
        </div>
        <div class="overflow-x-auto">
          <fwb-table striped>
            <fwb-table-head>
              <fwb-table-head-cell>Exercise</fwb-table-head-cell>
              <fwb-table-head-cell>Weight</fwb-table-head-cell>
              <fwb-table-head-cell>Reps</fwb-table-head-cell>
              <fwb-table-head-cell>Date</fwb-table-head-cell>
              <fwb-table-head-cell>Equipment</fwb-table-head-cell>
              <fwb-table-head-cell>
                <span class="sr-only">Actions</span>
              </fwb-table-head-cell>
            </fwb-table-head>
            <fwb-table-body>
              <fwb-table-row v-for="pr in paginatedPRs" :key="pr.id">
                <fwb-table-cell>{{ pr.exercise }}</fwb-table-cell>
                <fwb-table-cell>{{ pr.weight }}</fwb-table-cell>
                <fwb-table-cell>{{ pr.reps }}</fwb-table-cell>
                <fwb-table-cell>{{ pr.date }}</fwb-table-cell>
                <fwb-table-cell>{{ pr.equipment }}</fwb-table-cell>
                <fwb-table-cell>
                  <span @click="showEditModal(pr)" class="cursor-pointer text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600">Edit/Delete</span>
                </fwb-table-cell>
              </fwb-table-row>
            </fwb-table-body>
          </fwb-table>
        </div>
        <div class="flex justify-between items-center p-4">
          <button @click="previousPage" :disabled="currentPage === 1" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Previous
          </button>
          <span class="text-gray-900 dark:text-white">Page {{ currentPage }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Next
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- Add/Edit PR modal -->
  <fwb-modal v-if="isShowModal" @close="closeModal">
    <template #header>
      <div class="flex items-center text-lg">
        {{ isEditing ? 'Edit PR' : 'Add New PR' }}
      </div>
    </template>
    <template #body>
      <form @submit.prevent="isEditing ? updatePR() : addPR()">
        <div class="grid gap-4 mb-4 grid-cols-2">
          <div class="col-span-2">
            <label for="exercise" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Exercise</label>
            <select v-model="currentPR.exercise" id="exercise" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required>
              <option value="" disabled>Select exercise</option>
              <option v-for="exercise in exercises" :key="exercise.id" :value="exercise.name">{{ exercise.name }}</option>
            </select>
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label for="weight" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Weight</label>
            <input type="number" v-model="currentPR.weight" id="weight" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Weight lifted">
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label for="reps" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reps</label>
            <input type="number" v-model="currentPR.reps" id="reps" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Number of reps" required>
          </div>
          <div class="col-span-2">
            <label for="date" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date</label>
            <input type="date" v-model="currentPR.date" id="date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required>
          </div>
          <div class="col-span-2">
            <label for="equipment" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Equipment</label>
            <select v-model="currentPR.equipment" id="equipment" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
              <option value="" disabled>Select equipment</option>
              <option value="Barbell">Barbell</option>
              <option value="Dumbbell">Dumbbell</option>
              <option value="Machine">Machine</option>
            </select>
          </div>
        </div>
        <div class="flex space-x-2 mt-4">
          <button type="submit" class="flex-1 text-white inline-flex items-center justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
            {{ isEditing ? 'Update PR' : 'Add PR' }}
          </button>
          <button v-if="isEditing" @click="deletePR(currentPR.id)" type="button" class="flex-1 text-white inline-flex items-center justify-center bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800">
            Delete PR
          </button>
        </div>
      </form>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <fwb-button @click="closeModal" color="alternative">
          Cancel
        </fwb-button>
      </div>
    </template>
  </fwb-modal>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { FwbButton, FwbModal, FwbTable, FwbTableBody, FwbTableCell, FwbTableHead, FwbTableHeadCell, FwbTableRow } from 'flowbite-vue'
import { collection, getDocs, addDoc, query, where, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { auth } from '../firebase' // Adjust the import according to your firebase config file path
import Swal from 'sweetalert2'
import { db } from '../firebase'

const isShowModal = ref(false)
const exercises = ref([])
const prs = ref([])
const currentUser = ref(null)
const isEditing = ref(false)
const currentPR = ref({
  exercise: '',
  weight: '',
  reps: '',
  date: new Date().toISOString().split('T')[0], // Set default to current date
  equipment: ''
})
const searchQuery = ref('')
const sortOrder = ref('newest') // Add sorting order state

const currentPage = ref(1)
const itemsPerPage = ref(5) // Set the number of items per page

const filteredPRs = computed(() => {
  let sortedPRs = [...prs.value]

  // Sort PRs based on the selected sorting order
  if (sortOrder.value === 'newest') {
    sortedPRs.sort((a, b) => new Date(b.date) - new Date(a.date))
  } else if (sortOrder.value === 'oldest') {
    sortedPRs.sort((a, b) => new Date(a.date) - new Date(b.date))
  }

  // Filter PRs based on the search query
  if (searchQuery.value) {
    sortedPRs = sortedPRs.filter(pr =>
      pr.exercise.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pr.weight.toString().includes(searchQuery.value) ||
      pr.reps.toString().includes(searchQuery.value) ||
      pr.date.includes(searchQuery.value) ||
      pr.equipment.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
  }

  return sortedPRs
})

const paginatedPRs = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value
  const end = start + itemsPerPage.value
  return filteredPRs.value.slice(start, end)
})

const totalPages = computed(() => {
  return Math.ceil(filteredPRs.value.length / itemsPerPage.value)
})

function previousPage () {
  if (currentPage.value > 1) {
    currentPage.value--
  }
}

function nextPage () {
  if (currentPage.value < totalPages.value) {
    currentPage.value++
  }
}

function closeModal () {
  isShowModal.value = false
  isEditing.value = false
  resetCurrentPR()
}

function showAddModal () {
  isShowModal.value = true
  isEditing.value = false
  resetCurrentPR()
}

function showEditModal (pr) {
  isShowModal.value = true
  isEditing.value = true
  currentPR.value = { ...pr }
}

function resetCurrentPR () {
  currentPR.value = {
    exercise: '',
    weight: '',
    reps: '',
    date: new Date().toISOString().split('T')[0], // Reset default to current date
    equipment: ''
  }
}

async function fetchExercises() {
  const querySnapshot = await getDocs(collection(db, 'exercises'))
  exercises.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

async function fetchPRs() {
  if (!currentUser.value) return
  const q = query(collection(db, 'prs'), where('userId', '==', currentUser.value.uid))
  const querySnapshot = await getDocs(q)
  const prList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))

  // Create a map to keep track of the highest reps for each exercise and equipment combination
  const prMap = new Map()
  prList.forEach(pr => {
    const key = `${pr.exercise}-${pr.equipment}-${pr.weight}`
    if (!prMap.has(key) || pr.reps > prMap.get(key).reps) {
      prMap.set(key, pr)
    }
  })

  prs.value = Array.from(prMap.values())
}

async function addPR() {
  try {
    const prData = { 
      ...currentPR.value, 
      date: new Date(currentPR.value.date).toISOString().split('T')[0], 
      userId: currentUser.value.uid,
    }
    const docRef = await addDoc(collection(db, 'prs'), prData)
    prs.value.push({ ...prData, id: docRef.id })
    resetCurrentPR()
    Swal.fire({
      icon: 'success',
      title: 'PR added successfully!',
      showConfirmButton: false,
      timer: 1500
    })
    closeModal()
    fetchPRs() // Refresh the PRs list
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'There was an error adding your PR.',
      title: 'Error',
    })
    console.error('Error adding PR:', error)
  }
}

async function updatePR() {
  try {
    const prId = currentPR.value.id
    const prData = { 
      ...currentPR.value, 
      date: new Date(currentPR.value.date).toISOString().split('T')[0],
    }
    await updateDoc(doc(db, 'prs', prId), prData)
    const prIndex = prs.value.findIndex(pr => pr.id === prId)
    prs.value[prIndex] = { ...prData, id: prId }
    resetCurrentPR()
    Swal.fire({
      icon: 'success',
      title: 'PR updated successfully!',
      showConfirmButton: false,
      timer: 1500
    })
    closeModal()
    fetchPRs() // Refresh the PRs list
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'There was an error updating your PR.',
      title: 'Error',
    })
    console.error('Error updating PR:', error)
  }
}

async function deletePR(prId) {
  try {
    await deleteDoc(doc(db, 'prs', prId))
    prs.value = prs.value.filter(pr => pr.id !== prId)
    Swal.fire({
      icon: 'success',
      title: 'PR deleted successfully!',
      showConfirmButton: false,
      timer: 1500
    })
    closeModal()
    fetchPRs() // Refresh the PRs list
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'There was an error deleting your PR.',
      title: 'Error',
    })
    console.error('Error deleting PR:', error)
  }
}

onMounted(async () => {
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      currentUser.value = user
      await fetchPRs()
    } else {
      currentUser.value = null
      prs.value = []
    }
  })
  fetchExercises()
})
</script>
