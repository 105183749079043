<template>
  <footer class="bg-white rounded-lg shadow dark:bg-gray-900 fixed bottom-0 w-full border-t border-gray-200 dark:border-gray-700">
    <div class="w-full mx-auto max-w-screen-xl p-4 flex flex-col sm:flex-row items-center justify-between">
      <!-- Logout Link -->
      <ul class="flex items-center text-sm font-medium text-gray-500 dark:text-gray-400">
        <li v-if="user" class="mt-2 sm:mt-0">
          <a href="#" class="hover:underline ml-4" @click="logout">Log out</a>
        </li>
      </ul>
      <!-- Footer Message -->
      <div class="text-sm text-gray-500 dark:text-gray-400 mt-2 sm:mt-0">
        Created by 
        <a href="https://peakwebdesignllc.com" target="_blank" class="hover:underline">
          <span class="text-[#a6a6a6] dark:text-[#a6a6a6]">Peak</span>
          <span class="text-black dark:text-white">Web</span>
          <span class="text-blue-700 dark:text-blue-500">Design</span>
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const user = ref(null);
const router = useRouter();

onAuthStateChanged(auth, (authUser) => {
  if (authUser) {
    user.value = authUser;
  } else {
    user.value = null;
  }
});

const logout = async () => {
  try {
    await auth.signOut();
    user.value = null;
    console.log('User logged out');
    router.push('/'); // Redirect to login page
  } catch (error) {
    console.error('Error logging out:', error);
  }
};
</script>

<style scoped>
</style>
