<template>
  <fwb-navbar class="fixed w-full top-0 z-50 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700">
    <template #logo>
      <fwb-navbar-logo alt="PR Tracker logo" image-url="/images/logo.png" link="#">
        PR Tracker
      </fwb-navbar-logo>
    </template>
    <template #default="{ isShowMenu }">
      <fwb-navbar-collapse :is-show-menu="isShowMenu" class="w-full">
        <div class="flex flex-col sm:flex-row items-center justify-between w-full">
          <fwb-toggle v-model="isDarkMode" label="Dark Mode" @change="toggleDarkMode" />
        </div>
      </fwb-navbar-collapse>
    </template>
  </fwb-navbar>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  FwbNavbar,
  FwbNavbarCollapse,
  FwbNavbarLogo,
  FwbToggle
} from 'flowbite-vue'

const isDarkMode = ref(false)

onMounted(() => {
  // Check local storage for dark mode preference
  if (localStorage.getItem('dark-mode') === 'true') {
    isDarkMode.value = true
    document.documentElement.classList.add('dark')
  }
})

function toggleDarkMode() {
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
    localStorage.setItem('dark-mode', 'true')
  } else {
    document.documentElement.classList.remove('dark')
    localStorage.setItem('dark-mode', 'false')
  }
}
</script>

<style scoped>
/* Add any additional custom styles if needed */
</style>
