<template>
  <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
    <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
      <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div class="flex flex-col items-center justify-between p-4">
          <h2 class="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Add New Exercise</h2>
          <form @submit.prevent="submitExercise" class="w-full max-w-lg">
            <div class="grid gap-4 mb-4 grid-cols-1">
              <div>
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Exercise Name</label>
                <input
                  type="text"
                  v-model="exercise.name"
                  id="name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Enter exercise name"
                  required
                />
              </div>
              <div>
                <label for="primaryMuscle" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Primary Muscle</label>
                <select
                  v-model="exercise.primaryMuscle"
                  id="primaryMuscle"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                >
                  <option value="" disabled>Select primary muscle</option>
                  <option value="Chest">Chest</option>
                  <option value="Back">Back</option>
                  <option value="Shoulders">Shoulders</option>
                  <option value="Biceps">Biceps</option>
                  <option value="Triceps">Triceps</option>
                  <option value="Quads">Quads</option>
                  <option value="Hamstrings">Hamstrings</option>
                  <option value="Glutes">Glutes</option>
                  <option value="Calves">Calves</option>
                  <option value="Abs">Abs</option>
                </select>
              </div>
              <div>
                <label for="secondaryMuscle" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Secondary Muscle</label>
                <select
                  v-model="exercise.secondaryMuscle"
                  id="secondaryMuscle"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option value="" disabled>Select secondary muscle</option>
                  <option value="None">None</option>
                  <option value="Chest">Chest</option>
                  <option value="Back">Back</option>
                  <option value="Shoulders">Shoulders</option>
                  <option value="Biceps">Biceps</option>
                  <option value="Triceps">Triceps</option>
                  <option value="Quads">Quads</option>
                  <option value="Hamstrings">Hamstrings</option>
                  <option value="Glutes">Glutes</option>
                  <option value="Calves">Calves</option>
                  <option value="Abs">Abs</option>
                </select>
              </div>
              <div>
                <label for="bodyPart" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Body Part</label>
                <select
                  v-model="exercise.bodyPart"
                  id="bodyPart"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                >
                  <option value="" disabled>Select body part</option>
                  <option value="Upper">Upper Body</option>
                  <option value="Lower">Lower Body</option>
                </select>
              </div>
            </div>
            <button
              type="submit"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Add Exercise
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { db } from '../firebase' // Adjust the import according to your firebase config file path
import { collection, addDoc } from 'firebase/firestore'

const exercise = ref({
  name: '',
  primaryMuscle: '',
  secondaryMuscle: '',
  bodyPart: '',
})

const submitExercise = async () => {
  try {
    const docRef = await addDoc(collection(db, 'exercises'), exercise.value)
    console.log('Exercise added with ID:', docRef.id)
    // Clear the form
    exercise.value = {
      name: '',
      primaryMuscle: '',
      secondaryMuscle: '',
      bodyPart: '',
    }
  } catch (error) {
    console.error('Error adding exercise:', error)
  }
}
</script>
